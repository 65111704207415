<template>
  <div class="orderReminder">
    订单提醒
  </div>
</template>

<script>
export default {
  name: "orderReminder"
}
</script>

<style lang="scss">
.orderReminder{

}
</style>